<template>
  <div>
    <div class="my-4 mb-16">
      <Calendar />
    </div>

    <previous-classes />
  </div>
</template>

<script>
import Calendar from "@/components/dashboard/classes/Calendar.vue"
import PreviousClasses from "./PreviousClasses"

export default {
  name: "Classes",

  components: {
    Calendar,
    PreviousClasses,
  },

  computed: {
    totalEntries() {
      return `${
        this.page * 10 > this.batchDataList.total
          ? this.batchDataList.total
          : this.page * 10
      } of ${this.batchDataList.total} Entries`
    },
  },
}
</script>

<style lang="scss" scoped></style>
