<template>
  <div>
    <div class="mb-8">
      <ClassListSearch
        :course="course"
        :from-date="fromDate"
        :list-loading="listLoading"
        :query="query"
        :to-date="toDate"
        @input-change="handleInputChange"
      />
    </div>
    <ClassesList
      :classes-data-list="classesDataList"
      :handle-pagination="handlePagination"
      :handle-sort="handleSort"
      :headers="headers"
      :list-loading="listLoading"
      :page="page"
      :sort="sort"
      :total-entries="totalEntries"
    />
  </div>
</template>

<script>
import ClassListSearch from "@/components/dashboard/classes/ClassListSearch"
import ClassesList from "@/components/dashboard/classes/ClassesList"
import urls from "@/utils/urls"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"

export default {
  name: "PreviousClasses",

  components: { ClassListSearch, ClassesList },

  data: () => ({
    fromDate: "",
    toDate: "",
    sort: { fields: [], desc: [] },
    page: 1,
    listLoading: false,
    query: "",
    course: "",

    headers: [
      {
        text: "Name",
        value: "class_id.name",
        sortable: false,
      },
      { text: "Course", value: "course", sortable: false },
      { text: "Time", value: "time", sortable: false },
      { text: "", value: "action", align: "end", sortable: false },
    ],
    classesDataList: {
      data: [],
      total: 0,
    },
  }),

  computed: {
    numberOfPages() {
      return Math.ceil(this.classesDataList.total / 10)
    },

    totalEntries() {
      return `${
        this.page * 10 > this.classesDataList.total
          ? this.classesDataList.total
          : this.page * 10
      } of ${this.classesDataList.total} Entries`
    },
  },

  mounted() {
    this.getclassesDataList()
  },

  methods: {
    //handle Sort
    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.sort = {
        fields: sortBy,
        desc: sortDesc,
      }
      this.getclassesDataList()
    },

    handleInputChange(key, value) {
      this[key] = value
      key !== "page" && this.resetPageNo()
      this.getclassesDataList()
    },
    resetPageNo() {
      this.page = 1
    },

    async getclassesDataList() {
      this.listLoading = true
      try {
        const res = await this.$http.get(urls.classes.getCalendarSchedule, {
          params: {
            page: this.page,
            query: getValueOrNull(this.query),
            course: getValueOrNull(this.course),
            start_date: getValueOrNull(
              this.fromDate !== ""
                ? this.moment(this.fromDate).format("YYYY-MM-DD")
                : ""
            ),
            end_date: getValueOrNull(
              this.toDate !== ""
                ? this.moment(this.toDate).format("YYYY-MM-DD")
                : ""
            ),
          },
        })
        this.classesDataList = res.data
      } catch (error) {
        console.error(error)
      } finally {
        this.listLoading = false
      }
    },
    handlePagination(newPage) {
      this.page = newPage
      this.getclassesDataList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
