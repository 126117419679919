<template>
  <div>
    <v-sheet class="d-flex align-center" tile>
      <v-btn class="ma-2" icon @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="d-md-flex">
        <v-select
          v-model="type"
          class="ma-2"
          dense
          hide-details
          item-text="name"
          item-value="value"
          :items="types"
          label="Type"
          outlined
        />
        <v-select
          v-model="selectedCourse"
          class="ma-2"
          clearable
          dense
          :disabled="loading"
          hide-details
          item-text="name"
          item-value="uuid"
          :items="courseChoices"
          label="Course"
          outlined
          @change="getEvents"
        />
      </div>
      <v-spacer />
      <v-btn class="ma-2" icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <template v-if="!loading">
        <v-calendar
          ref="calendar"
          v-model="startDate"
          color="accent"
          :event-color="getEventColor"
          event-overlap-mode="column"
          :events="events"
          :type="type"
          @change="handleChange"
          @click:event="showEvent"
          @click:more="changeType"
        />
      </template>
      <template v-else>
        <div class="d-flex justify-center">
          <v-progress-circular class="mt-5" color="primary" indeterminate />
        </div>
      </template>
    </v-sheet>
  </div>
</template>

<script>
import urls from "@/utils/urls"
import { getValueOrNull } from "@/utils/helpers"
import { mapGetters } from "vuex"

function addDaysToDate(date, days = 0) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export default {
  name: "Calendar",

  data: () => ({
    loading: false,

    selectedBatch: null,
    selectedCourse: null,

    type: "month",
    types: [
      {
        name: "Month",
        value: "month",
      },
      {
        name: "Week",
        value: "week",
      },
      {
        name: "Day",
        value: "day",
      },
    ],

    startDate: new Date(),
    endDate: addDaysToDate(new Date(), 30),

    events: [],

    colors: [
      "green",
      "deep-orange",
      "purple",
      "deep-purple",
      "teal lighten-1",
      "grey lighten-1",
      "teal",
      "blue lighten-1",
      "cyan lighten-1",
      "brown lighten-1",
      "deep-purple lighten-1",
      "cyan",
      "light-green lighten-1",
      "brown",
      "amber lighten-1",
      "amber",
      "indigo lighten-1",
      "orange lighten-1",
      "pink",
      "orange",
      "pink lighten-1",
      "light-green",
      "deep-orange lighten-1",
      "grey",
      "green lighten-1",
      "purple lighten-1",
      "blue",
      "indigo",
    ],
  }),

  computed: {
    ...mapGetters(["courseChoices", "batchChoices"]),
  },

  methods: {
    async getEvents() {
      this.loading = true

      if (this.course === undefined) {
        this.course = null
      }
      if (this.batch === undefined) {
        this.batch = null
      }

      try {
        const res = await this.$http.get(urls.classes.getCalendarSchedule, {
          params: {
            start_date: this.moment(this.startDate).format("yyyy-MM-DD"),
            end_date: this.moment(this.endDate).format("yyyy-MM-DD"),
            batch: getValueOrNull(this.selectedBatch),
            course: getValueOrNull(this.selectedCourse),
            page: "all",
          },
        })

        this.events = res.data.data.map((schedule, index) => {
          return {
            id: schedule.id,
            name: schedule.class_id.name,
            start: this.formatDate(new Date(schedule.start_time)),
            end: this.formatDate(new Date(schedule.end_time)),
            color: this.colors[
              index > this.colors.length - 1
                ? index - this.colors.length - 1
                : index
            ],
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },

    handleChange({ start, end }) {
      if (this.startDate === start.date && this.endDate === end.date) return
      this.startDate = start.date
      this.endDate = end.date
      this.getEvents()
    },

    changeType({ date }) {
      this.startDate = date
      this.type = "day"
    },
    showEvent(e) {
      // TODO: Fix this route name and params

      this.$router.push({
        name: "ViewLectureStudentList",
        params: {
          classId: e.event.id,
        },
      })
    },
    getEventColor(event) {
      return event.color
    },
    formatDate(a) {
      return `${a.getFullYear()}-${a.getMonth() +
        1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
